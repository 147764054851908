<template>
  <div class="es-footer-link" :class="{ 'is-not-homepage': !isHomePage }">
    <div class="bt-container">
      <div class="footer-login-wrap">
        <a href="/" target="_blank">
          <img src="@/assets/images/footer/logo_footer.png" alt="BT学院LOGO" class="footer-logo-img" />
        </a>
        <p class="slogan">{{ activeLinks.describe }}</p>
      </div>
      <div class="footer-mean-wrap">
        <div class="mean-item" v-for="(item, idx) in activeLinks.links" :key="idx" :style="{ width: 100 / activeLinks.links.length + '%' }">
          <template v-for="(cItem, i) in item">
            <div class="mean-title" :key="i" v-if="i == 0">{{ cItem.value }}</div>
            <template v-else>
              <div class="mean-children" :key="i">
                <a class="children-item" :href="cItem.href" :target="cItem.target">{{ cItem.value }}</a>
              </div>
            </template>
          </template>
        </div>
      </div>
      <!-- 正常是400px， 767分辨以下隐藏， 768～992分辨率 420px -->
      <div class="footer-code-wrap">
        <div class="app-code-wrap">
          <div class="code-title">APP下载</div>
          <img src="https://tmp.btclass.cn/yunyin/bottom-code1.png" alt="APP下载" class="footer-code-img" />
        </div>
        <div class="wx-code-wrap">
          <div class="code-title">微信公众号</div>
          <img src="https://tmp.btclass.cn/yunyin/bottom-code2.png" alt="微信公众号" class="footer-code-img" />
        </div>
        <div class="wx-code-wrap">
          <div class="code-title">微信小程序</div>
          <img src="https://tmp.btclass.cn/yunyin/bottom-code3.png" alt="微信公众号" class="footer-code-img" />
        </div>
      </div>
      <div class="footer-media-wrap">
        <a class="media-item" target="_blank" rel="nofollow" href="https://weibo.com/p/1006065796658277/home?from=page_100606&mod=TAB&is_all=1">
          <!-- <img src="@/assets/images/footer/wb_h.png" v-if="activeMedia === 'wb'" alt="新浪微博" class="footer-media-img" />
          <img src="@/assets/images/footer/wb.png" v-else alt="新浪微博" class="footer-media-img" /> -->
          <div class="footer-media-img wb-img"></div>
          <div class="media-title">微博@BT教育</div>
        </a>
        <a class="media-item" target="_blank" rel="nofollow" href="https://www.zhihu.com/org/bt-xue-yuan">
          <!-- <img src="@/assets/images/footer/zh_h.png" v-if="activeMedia === 'zh'" alt="知乎" class="footer-media-img" />
          <img src="@/assets/images/footer/zh.png" v-else alt="知乎" class="footer-media-img" /> -->
          <div class="footer-media-img zh-img"></div>
          <div class="media-title">知乎@BT教育</div>
        </a>
        <a
          class="media-item"
          target="_blank"
          rel="nofollow"
          href="https://btclass.tmall.com/shop/view_shop.htm?spm=a220m.1000858.1000725.3.441b2044OiQWXH&user_number_id=3251581023&rn=2ebdbf0b9712d00b84caab6b36a32c3a"
        >
          <!-- <img src="@/assets/images/footer/tm_h.png" v-if="activeMedia === 'tm'" alt="天猫旗舰店" class="footer-media-img" />
          <img src="@/assets/images/footer/tm.png" v-else alt="天猫旗舰店" class="footer-media-img" /> -->
          <div class="footer-media-img tm-img"></div>
          <div class="media-title">BT学院天猫旗舰店</div>
        </a>
      </div>
    </div>
    <div class="friend-link" v-if="activeLinks.friendlyLinks && activeLinks.friendlyLinks.length">
      <div class="footer-link-des">友情链接：</div>
      <div class="link-wrap">
        <a :href="item.shortUrl[0]" rel="nofollow" :target="item.target" v-for="item in activeLinks.friendlyLinks" :key="item.id" class="link">{{ item.title }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
// import QRCode from 'qrcode';
export default {
  name: 'FooterLink',
  computed: {
    ...mapGetters(['activeLinks']),
    isHomePage() {
      return this.$route.name === 'home';
    },
  },
  data() {
    return {
      qrcodeUrl: '',
      params: {
        name: 'bt_official_web_bottom',
      },
    };
  },
  methods: {
    ...mapActions(['getActiveLinks']),
  },
  serverPrefetch() {
    return this.getActiveLinks(this.params);
  },
  async mounted() {
    if (!Object.keys(this.activeLinks).length) {
      this.getActiveLinks(this.params);
    }
    // this.qrcodeUrl = await QRCode.toDataURL(`${process.env.VUE_APP_WHALE_URL}download-app`);
    this.qrcodeUrl = 'https://tmp.btclass.cn/yunyin/o_1gjo63f0ddh7hug9g414cg143pa.png';
  },
};
</script>
<style scoped lang="scss">
.bt-container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid #676767;
}
.es-footer-link {
  background: #2a2a2a;
  //
  .pc-container {
    padding: 0 10px;
  }
  .footer-login-wrap {
    width: 220px;
    min-width: 220px;
    margin-top: 12px;
    padding-right: 36px;
    .footer-logo-img {
      height: 47px;
      width: 153px;
    }
    .slogan {
      font-size: 12px;
      font-weight: 400;
      color: #ababab;
      line-height: 17px;
      margin-top: 16px;
    }
  }

  .footer-mean-wrap {
    display: flex;
    justify-content: space-between;
    width: 440px;
    min-width: 440px;
    .mean-title {
      font-size: 16px;
      font-weight: bolder;
      color: #F7F8F9;
      line-height: 1;
    }
    .mean-children {
      margin-top: 16px;
    }
    .children-item {
      font-size: 12px;
      font-weight: 400;
      color: #ababab;
      text-decoration: none;
      display: block;
      line-height: 1;
      margin-bottom: 8px;
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
  }
  .footer-code-wrap {
    display: flex;
    width: 390px;
    min-width: 390px;
    margin-left: 20px;

    .footer-code-img {
      margin-top: 12px;
      height: 90px;
      width: 90px;
    }
    .code-title {
      font-size: 16px;
      font-weight: 400;
      color: #808080;
    }
    .wx-code-wrap {
      margin-left: 40px;
    }
  }
  .footer-media-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    min-width: 200px;
    padding-bottom: 8px;
    margin-left: 20px;
    height: 136px;
    .media-item {
      // margin-left: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      &:hover,
      &:focus,
      &:active {
        .media-title {
          color: #fff;
        }
        .footer-media-img {
          background-color: #c0c0c0;
        }
      }
    }
    .footer-media-img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #808080;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-right: 8px;
      &.wb-img {
        background-image: url('../../../../assets/images/footer/icon-wb.png');
      }
      &.zh-img {
        background-image: url('../../../../assets/images/footer/icon-zhihu.png');
      }
      &.tm-img {
        background-image: url('../../../../assets/images/footer/icon-tm.png');
      }
    }
    .media-title {
      font-size: 12px;
      font-weight: 400;
      color: #ababab;
    }
  }
  .friend-link {
    display: flex;
    max-width: 1300px;
    margin: 16px auto 0;
    font-size: 14px;
    color: #808080;
    .footer-link-des {
      min-width: 70px;
      line-height: 1;
    }
    .link-wrap {
      display: flex;
      flex-wrap: wrap;
      // height: 25px;
      overflow: hidden;
    }
    .link {
      color: #ababab;
      text-decoration: none;
      font-size: 12px;
      margin-left: 24px;
      margin-bottom: 9px;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1200px) {
  .is-not-homepage {
    .footer-login-wrap {
      display: none;
    }
  }
}
@media (max-width: 992px) {
  .is-not-homepage {
    .footer-media-wrap {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .is-not-homepage {
    .footer-code-wrap {
      display: none;
    }
    .friend-link {
      display: none;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .es-footer-link {
    .bt-container {
      max-width: 1200px;
    }
    .friend-link {
      width: 1200px;
    }
    .footer-mean-wrap {
      width: 340px;
      min-width: 340px;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .es-footer-link.is-not-homepage {
    .bt-container {
      max-width: 992px;
    }
    .friend-link {
      width: 992px;
    }
    .footer-mean-wrap {
      width: 385px;
      min-width: 385px;
    }
    .footer-media-wrap {
      margin-left: 0px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .es-footer-link.is-not-homepage {
    .bt-container {
      max-width: 768px;
      justify-content: space-between;
    }
    .friend-link {
      width: 768px;
    }
    .footer-mean-wrap {
      width: 340px;
      min-width: 340px;
    }
    .footer-code-wrap {
      width: 400px;
      min-width: 400px;
    }
  }
}
@media (max-width: 767px) {
  .es-footer-link.is-not-homepage {
    padding: 20px 0;
    .footer-mean-wrap {
      min-width: 340px;
      width: 340px;
    }
    .bt-container {
      justify-content: center;
      min-width: 375px;
    }
  }

  .is-not-homepage {
    .footer-code-wrap {
      display: none;
    }
    .friend-link {
      display: none;
    }
  }
}
</style>
