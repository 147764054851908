<template>
  <footer :class="{'home-page-footer': isHomePage}">
    <footer-link></footer-link>
    <l-footer></l-footer>
  </footer>
</template>

<script>
import LFooter from './components/Footer';
import FooterLink from './components/FooterLink';
export default {
  name: 'Footer',
  components: {
    LFooter,
    FooterLink
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'home';
    },
  },
};
</script>

<style lang="scss" scoped>
  .home-page-footer {
    min-width: 1300px;
  }
</style>
